import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { getToken, getUserId } from '../../auth/auth.js';
import { BASE_URL } from '../../Constants';
import { format, isToday, isYesterday  } from 'date-fns';
import ChatHistory from './ChatHistory';
import { alpha } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import { keyframes } from '@mui/system';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ChatIcon from '@mui/icons-material/Chat';
import Tooltip from '@mui/material/Tooltip';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ImageIcon from '@mui/icons-material/Image';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
import PreviewIcon from '@mui/icons-material/Visibility';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import debounce from 'lodash/debounce';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TableViewIcon from '@mui/icons-material/TableView';
import AddIcon from '@mui/icons-material/Add';

const CLEAR_COMMANDS = ['/clear','clear' ,'clear my convo', 'clear chat', 'clear my chat', 'clear', 'clear convo', 'clea'];

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const blinkAnimation = keyframes`
  from, to { opacity: 1; }
  50% { opacity: 0; }
`;

const TYPING_DEBOUNCE_TIME = 500;

// Add this helper function near the top of the file
const extractSqlQueries = (text) => {
  // List of forbidden SQL keywords that could modify data
  const forbiddenKeywords = [
    'INSERT',
    'UPDATE',
    'DELETE',
    'DROP',
    'CREATE',
    'ALTER',
    'TRUNCATE',
    'REPLACE',
    'MERGE',
    'UPSERT',
    'GRANT',
    'REVOKE'
  ];
  
  const sqlRegex = /```sql\s*([\s\S]*?)\s*```/g;
  const matches = [];
  let match;
  
  while ((match = sqlRegex.exec(text)) !== null) {
    const query = match[1].trim();
    
    // Check if query contains any forbidden keywords
    const containsForbiddenKeyword = forbiddenKeywords.some(keyword => 
      new RegExp(`\\b${keyword}\\b`, 'i').test(query)
    );
    
    // Only add safe queries (SELECT statements without forbidden keywords)
    if (query.toUpperCase().trim().startsWith('SELECT') && !containsForbiddenKeyword) {
      matches.push(query);
    }
  }
  
  return matches;
};

// Create a new component for the collapsible SQL code block
const CollapsibleCodeBlock = ({ code, language }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <Paper
      sx={{
        p: 2,
        my: 1,
        backgroundColor: theme => 
          theme.palette.mode === 'light'
            ? 'rgba(40, 44, 52, 0.95)'
            : 'rgba(40, 44, 52, 0.95)',
        color: '#abb2bf',
        fontFamily: 'Consolas, Monaco, "Courier New", monospace',
        fontSize: '0.9rem',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '8px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          onClick={toggleExpand}
          sx={{
            color: '#666',
            fontSize: '0.7rem',
            textTransform: 'uppercase',
            mb: 1,
          }}
        >
          {isExpanded ? 'Collapse' : 'Expand'}
        </Button>
        <Typography
          variant="caption"
          sx={{
            color: '#666',
            textTransform: 'uppercase',
            fontSize: '0.7rem',
          }}
        >
          {language}
        </Typography>
      </Box>

      {isExpanded && (
        <pre style={{ margin: 0, whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
          <code>{code}</code>
        </pre>
      )}
    </Paper>
  );
};

// Modify the formatCodeBlock function to use the new component
const formatCodeBlock = (code, language) => {
  return <CollapsibleCodeBlock code={code} language={language} />;
};

function ChatAI() {
  const [allThreadMessages, setAllThreadMessages] = useState([]);
  const [currentUserMessageText, setCurrentUserMessage] = useState('');
  const [isNewMessageLoading, setIsNewMessageLoading] = useState(false);
  const [isPreviousMessagesLoading, setIsPreviousMessagesLoading] = useState(false);
  const [typingUsers, setTypingUsers] = useState(new Set());
  const [typingUsersNames, setTypingUsersNames] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [streamingMessage, setStreamingMessage] = useState('');
const [isStreaming, setIsStreaming] = useState(false);

  const listRef = useRef(null);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const isScrollLocked = useRef(false);

  const [ws, setWs] = useState(null);
  const TYPING_TIMEOUT = 3000; 
 
  const token = getToken();
  const threadId = localStorage.getItem('threadId');
  const userId = getUserId();

  const [threads, setThreads] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null); // State for selected file

  // WEB SOCKET CONNECTION
  useEffect(() => { 
    let ws = null;
    let pingInterval = null;
    let reconnectAttempt = 0;
    let reconnectTimeout = null;
    const maxReconnectDelay = 30000; // Maximum delay between reconnection attempts (30 seconds)
    let isComponentMounted = true;
  
    const connect = () => {
      const websocketProtocol = BASE_URL.startsWith('https') ? 'wss://' : 'ws://';
      const websocketHost = BASE_URL.replace(/https?:\/\//, '');
      const websocketURL = `${websocketProtocol}${websocketHost}/ws`;
  
      ws = new WebSocket(websocketURL);
  
      ws.onopen = () => {
        console.log('WebSocket Connected');
        if (threadId) {
          console.log(`Joining thread ${threadId} with userId ${userId}`);
          ws.send(JSON.stringify({ type: 'join_thread', threadId, userId }));
        }
  
        // Set up ping interval
        pingInterval = setInterval(() => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ type: 'ping' }));
          }
        }, 30000); // Send a ping every 30 seconds
      }; 
  
      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received message:', data);

          if (data.type === 'stream_start') {
            setIsNewMessageLoading(false);
            setIsStreaming(true);
            setStreamingMessage('');
            setAllThreadMessages(prevMessages => {
              const messagesWithoutTempAssistant = prevMessages.filter(msg => 
                !msg.temporary || msg.role === 'user'
              );
              return [...messagesWithoutTempAssistant, {
                role: 'assistant',
                content: [{
                  type: 'text',
                  text: { value: '' }
                }],
                user: 'AI',
                created_at: Math.floor(Date.now() / 1000),
                id: `stream-${Date.now()}`,
                temporary: true
              }];
            });
          }
          
          else if (data.type === 'stream_delta') {
            setAllThreadMessages(prevMessages => {
              const newMessages = [...prevMessages];
              const lastMessage = newMessages[newMessages.length - 1];
              if (lastMessage && lastMessage.temporary && lastMessage.role === 'assistant') {
                lastMessage.content = [{
                  type: 'text',
                  text: { value: lastMessage.content[0].text.value + data.content }
                }];
              }
              return newMessages;
            });
          }
          
          else if (data.type === 'stream_end') {
            setIsStreaming(false);
            setAllThreadMessages(prevMessages => {
              const messagesWithoutTemp = prevMessages.filter(msg => !msg.temporary);
              
              if (data.messages && Array.isArray(data.messages)) {
                const newMessages = data.messages.map(msg => {
                  // Only check for SQL queries if this is the original sender's client
                  console.log('msg.user_id', msg.user_id);
                  console.log('userId', userId);  
                  console.log('msg.role', msg.role);
                  if (msg.role === 'assistant' && msg.user_id === userId) {
                    const sqlQueries = extractSqlQueries(msg.content);
                    console.log('sqlQueries', sqlQueries);
                    if (sqlQueries.length > 0) {
                      // Send SQL queries to backend
                      sqlQueries.forEach(query => {
                        ws.send(JSON.stringify({
                          type: 'execute_sql',
                          threadId: threadId,
                          query: query,
                          messageId: msg.id
                        }));
                      });
                    }
                  }
                  
                  return {
                    role: msg.role,
                    content: msg.file ? [
                      ...(msg.content ? [{
                        type: 'text',
                        text: { value: msg.content }
                      }] : []),
                      {
                        type: msg.file.type.startsWith('image/') ? 'image' : 'non_image',
                        file: {
                          name: msg.file.name,
                          type: msg.file.type,
                          file_id: msg.file.file_id
                        }
                      }
                    ] : [{
                      type: 'text',
                      text: { value: msg.content }
                    }],
                    user: msg.role === 'assistant' ? 'AI' : 'You',
                    created_at: msg.created_at,
                    user_id: msg.user_id,
                    first_name: msg.first_name,
                    last_name: msg.last_name,
                    id: msg.id || `${Date.now()}-${Math.random()}`
                  };
                });
                return [...messagesWithoutTemp, ...newMessages];
              }
              return messagesWithoutTemp;
            });
            setStreamingMessage('');
            
            if (data.newTitle) {
              setThreads(prevThreads => 
                prevThreads.map(thread => 
                  thread.threadId === threadId ? { ...thread, title: data.newTitle } : thread
                )
              );
            }
          }
          
          else if (data.type === 'incoming_message_no_ai') {
            setAllThreadMessages(prevMessages => {
              const filteredMessages = prevMessages.filter(msg => !msg.temporary);
              const newMessages = data.messages.filter(newMsg => 
                !filteredMessages.some(existingMsg => existingMsg.id === newMsg.id)
              );
              return [...filteredMessages, ...newMessages];
            });
          }
          
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };
  
      ws.onerror = (error) => {
        console.error('WebSocket Error:', error);
        // Handle error...
      };
  
      ws.onclose = (event) => {
        console.log('WebSocket Closed:', event);
        clearInterval(pingInterval);
  
        if (isComponentMounted) {
          const delay = Math.min(1000 * (2 ** reconnectAttempt), maxReconnectDelay);
          console.log(`Reconnecting in ${delay}ms...`);
          reconnectTimeout = setTimeout(() => {
            reconnectAttempt++;
            connect();
          }, delay);
        }
      };

      setWs(ws);
    };
  
    connect();
  
    return () => {
      isComponentMounted = false;
      if (ws) {
        ws.close();
      }
      clearInterval(pingInterval);
      clearTimeout(reconnectTimeout);
    };

  }, [threadId, BASE_URL]);

  const handleClearChat = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/openai/createthread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          isClearing: true,
          title: 'New Chat'
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.details || 'Failed to clear chat');
      }

      const data = await response.json();
      localStorage.setItem('threadId', data.threadId);
      setAllThreadMessages([]);
      
      // Add the new thread to the threads list using created_at from DB
      setThreads(prevThreads => [{
        threadId: data.threadId,
        createdAt: data.created_at, // Use the timestamp from the server
        lastMessage: '',
        title: data.title
      }, ...prevThreads]);

      // Rejoin websocket with new thread
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ 
          type: 'join_thread', 
          threadId: data.threadId,
          userId: userId 
        }));
      }
    } catch (error) {
      console.error('Error clearing chat:', error);
      toast.error(error.message || 'Failed to clear chat');
    }
  };

  // Extract the clear command check into a separate function
  const handleClearCommand = (message) => {
    return CLEAR_COMMANDS.includes(message.toLowerCase().trim());
  };

  // Update the MessageInput component
  const MessageInput = useMemo(() => (
    <TextField
      inputRef={inputRef}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault(); // Prevent default behavior
          // Remove the handleSendMessage call
        }
      }}
      multiline
      maxRows={4}
      placeholder="Type a message..."
      fullWidth
      variant="outlined"
       sx={{
      '& .MuiInputBase-root': {
        height: '45px', // Match button height
        padding: '8px 14px', // Adjust padding for better text alignment
        alignItems: 'center',
      },
      '& .MuiInputBase-input': {
        padding: 0, // Remove default padding
        lineHeight: '1.5', // Adjust line height
      },
      '& .MuiOutlinedInput-root.MuiInputBase-multiline': {
          height: 'auto', // Allow height to grow when multiline
          padding: '8px 14px', // Keep consistent padding
        },
      }}
    />
  ), []);

  const handleSendMessage = async () => {
    // Get the message directly from the input field
    const userMessageText = inputRef.current.value;
    if (!userMessageText && !selectedFile) return;
    
    // Clear input field
    inputRef.current.value = '';
    setCurrentUserMessage('');
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    if (handleClearCommand(userMessageText)) {
      handleClearChat();
      return;
    }

    setIsNewMessageLoading(true);

    try {
      const userInfo = await fetchName(userId);
      let previewMessage = {
        role: 'user',
        content: [],
        user: 'You',
        created_at: Math.floor(Date.now() / 1000),
        user_id: userId,
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
        temporary: true,
        id: `temp-${Date.now()}`
      };

      // Add text content if exists - Use the raw message from input
      if (userMessageText) {
        previewMessage.content.push({
          type: 'text',
          text: { value: userMessageText }
        });
      }

      // Convert file to base64 if selected
      let base64File = null;
      if (selectedFile) {
        base64File = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(selectedFile);
        });

        previewMessage.content.push({
          type: selectedFile.type.startsWith('image/') ? 'image' : 'non_image',
          file: {
            name: selectedFile.name,
            type: selectedFile.type,
            data: base64File
          }
        });
      }

      // Add preview message to UI immediately
      setAllThreadMessages(prevMessages => [...prevMessages, previewMessage]);

      // Send message via WebSocket
      if (ws && ws.readyState === WebSocket.OPEN) {
        const messageData = {
          type: 'new_user_message',
          userId: userId,
          content: userMessageText.trim(),
          threadId: threadId,
          timestamp: Math.floor(Date.now() / 1000)
        };

        if (selectedFile) {
          messageData.file = {
            name: selectedFile.name,
            type: selectedFile.type,
            data: base64File
          };
        }

        ws.send(JSON.stringify(messageData));
      }

      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

    } catch (error) {
      console.error('Error in handleSendMessage:', error);
      toast.error('Failed to send message');
      setIsNewMessageLoading(false);
    }
  };

  const handleSendMessageWithoutAI = async () => {
    // Get the message directly from the input field
    const userMessageText = inputRef.current.value;
    if (!userMessageText && !selectedFile) return;
    
    // Clear input field
    inputRef.current.value = '';
    setCurrentUserMessage('');
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    if (handleClearCommand(userMessageText)) {
      handleClearChat();
      return;
    }

    try {
      const userInfo = await fetchName(userId);
      let previewMessage = {
        role: 'user',
        content: [],
        user: 'You',
        created_at: Math.floor(Date.now() / 1000),
        user_id: userId,
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
        temporary: true,
        id: `temp-${Date.now()}`
      };

      // Add text content if exists - Use the raw message from input
      if (userMessageText) {
        previewMessage.content.push({
          type: 'text',
          text: { value: userMessageText }
        });
      }

      // Handle file preview if exists
      if (selectedFile) {
        const base64File = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(selectedFile);
        });

        previewMessage.content.push({
          type: selectedFile.type.startsWith('image/') ? 'image' : 'non_image',
          file: {
            name: selectedFile.name,
            type: selectedFile.type,
            preview: base64File
          }
        });
      }

      // Add preview message to UI immediately
      setAllThreadMessages(prevMessages => [...prevMessages, previewMessage]);

      // Send message via WebSocket
      if (ws && ws.readyState === WebSocket.OPEN) {
        const messageData = {
          type: 'new_user_message_no_ai',
          userId: userId,
          content: userMessageText.trim(),
          threadId: threadId,
          timestamp: Math.floor(Date.now() / 1000)
        };

        if (selectedFile) {
          messageData.file = {
            data: previewMessage.content.find(c => c.file)?.file.preview,
            name: selectedFile.name,
            type: selectedFile.type
          };
        }

        ws.send(JSON.stringify(messageData));
      }

      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

    } catch (error) {
      console.error('Error in handleSendMessageWithoutAI:', error);
      toast.error('Failed to send message');
    }
  };

  // only being used for initial render
  const fetchInitialMessages = async (threadId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/openai/fetchmessages/${threadId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const fetchedMessagesData = await response.json();
      
      // Sort messages by timestamp, oldest first
      const sortedMessages = fetchedMessagesData.messages.sort((a, b) => a.created_at - b.created_at);
      setAllThreadMessages(sortedMessages);
      
      // Scroll to bottom after messages are loaded
      requestAnimationFrame(() => {
        scrollToBottom(true);
      });
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const fetchOlderMessages = async (threadId, limit = 20, before = null) => {
    try {
      let url = `${BASE_URL}/api/openai/fetchmessages/${threadId}?limit=${limit}`;
      if (before) {
        url += `&before=${before}`;
      }
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      const fetchedMessagesData = await response.json();
      console.log({fetchedMessagesData}); // debug
  
      // Sort messages by timestamp, oldest first
      return fetchedMessagesData.messages.sort((a, b) => a.created_at - b.created_at);
    } catch (error) {
      console.error('Error fetching older messages:', error);
      return [];
    }
  };

  const fetchThreads = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/openai/threads`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      
      if (data.threads.length > 0) {
        const latestThread = data.threads.reduce((oldest, current) => {
          return current.created_at > oldest.created_at ? current : oldest;
        }, data.threads[0]);
        const latestThreadId = latestThread.chatbot_thread_id;
        // const latestThreadId = data.threads[0].chatbot_thread_id;
        // const latestThreadId = 'thread_ngls6v72Ilm51M8c4i2v8X4B'; // hackkk
        localStorage.setItem('threadId', latestThreadId);
        fetchInitialMessages(latestThreadId);
      }
    } catch (error) {
      console.error('Error fetching threads:', error);
    }
  };

  const fetchName = useCallback(async (userId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/readuser/${userId}`, {
        method: 'GET',
        headers: { 
          'Authorization': `Bearer ${token}` }
      });
      if (!response.ok) throw new Error('Failed to fetch user info');
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching user info:', error);
      return null;
    }
  }, [BASE_URL, token]);

  useEffect(() => {
    fetchThreads();
  }, []);

  // Replace typing timeout state with a debounced function
  const debouncedTypingUpdate = useMemo(
    () => debounce((isTyping) => {
      if (ws?.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({
          type: 'user_typing',
          threadId,
          userId,
          isTyping
        }));
      }
    }, TYPING_DEBOUNCE_TIME),
    [ws, threadId, userId]
  );



  // 1. First, declare handleBlur
  const handleBlur = useCallback(() => {
    debouncedTypingUpdate(false);
  }, [debouncedTypingUpdate]);

  // 2. Then handleMessageChange
  const handleMessageChange = useCallback((event) => {
    try {
      const newValue = event.target.value;
      
      if (newValue === currentUserMessageText) {
        return;
      }

      if (inputRef.current) {
        inputRef.current.value = newValue;
      }

      requestAnimationFrame(() => {
        setCurrentUserMessage(newValue);
        
        if (newValue.length > 0 && !typingUsers.has(userId)) {
          debouncedTypingUpdate(true);
        } else if (newValue.length === 0) {
          debouncedTypingUpdate(false);
        }
      });
    } catch (error) {
      console.error('Error in handleMessageChange:', error);
      setCurrentUserMessage(event.target.value || '');
    }
  }, [currentUserMessageText, debouncedTypingUpdate, typingUsers, userId]);


  // Cleanup debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedTypingUpdate.cancel();
    };
  }, [debouncedTypingUpdate]);

  const formatNameAndTime = (firstName, lastName, timestamp, isAssistant) => {
    if (!timestamp) {
      return (
        <span style={{ fontWeight: 'bold' }}>
          {firstName} (Invalid Time):
        </span>
      );
    }
  
    const date = new Date(timestamp * 1000);
    const formattedDate = format(date, 'MMMM d, yyyy, hh:mm a');
    const formattedName = isAssistant ? firstName : (lastName ? `${firstName}` : firstName);
  
    return (
      <span style={{ fontWeight: 'bold' }}>
        {formattedName} ({formattedDate}):
      </span>
    );
  };

  const formatDate = (timestamp) => {
    if (!timestamp) {
      console.error('Invalid timestamp:', timestamp);
      return 'Invalid Date';
    }
    const date = new Date(timestamp * 1000);
    if (isToday(date)) {
      console.log({ isToday }); // debug
      return 'Today';
    } else if (isYesterday(date)) {
      return 'Yesterday';
    } else {
      return format(date, 'MMMM d, yyyy');
    }
  };

  // Optimized smooth scroll function
  const scrollToBottom = useCallback((force = false) => {
    if (!listRef.current || isScrollLocked.current) return;

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const isAtBottom = scrollHeight - scrollTop - clientHeight < 100;

    if (isAtBottom || force) {
      isScrollLocked.current = true;
      listRef.current.scrollTo({
        top: scrollHeight,
        behavior: 'smooth'
      });
      
      // Reset scroll lock after animation
      setTimeout(() => {
        isScrollLocked.current = false;
      }, 300); // Match scroll animation duration
    }
  }, []);

  // Update scroll effect for streaming
  useEffect(() => {
    if (isStreaming) {
      const scrollInterval = setInterval(() => {
        scrollToBottom(true);
      }, 300); // Adjust timing for smoother scroll

      return () => clearInterval(scrollInterval);
    }
  }, [isStreaming, scrollToBottom]);

  const loadMoreMessages = useCallback(() => {
    // This function can be removed or simplified since we're loading all messages at once
    return;
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // No need to load more messages since we're loading all at once
      return;
    };

    const listElement = listRef.current;
    listElement.addEventListener('scroll', handleScroll);
    return () => listElement.removeEventListener('scroll', handleScroll);
  }, []); 

  // Add this helper function
  const removeTimestamps = (text) => {
    if (!text) return '';
    // This regex will match patterns like 36:19†source】 and remove them
    return text.replace(/【\d+:\d+†[^】]*】/g, '');
  };

  // Modify the stripContextFromMessage function
  function stripContextFromMessage(message) {
    if (!message || typeof message !== 'string') {
      return '';
    }
    const withoutContext = message.replace(/^\[.*?\]\s*/, '');
    return removeTimestamps(withoutContext); // Add this line
  }

  // Alternatively, you could combine them into one function:
  function cleanMessage(message) {
    if (!message || typeof message !== 'string') {
      return '';
    }
    return message
      .replace(/^\[.*?\]\s*/, '') // Remove context
      .replace(/【\d+:\d+†[^]*】/g, '') // Remove timestamps
      .replace(/uploaded a file with file id: [^\s]+/g, ''); // Remove file ID text
  }

  useEffect(() => {
    const fetchThreads = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/openai/threads`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        
        // Update the threads mapping to include isPinned status
        const sortedThreads = data.threads
          .map(thread => ({
            threadId: thread.chatbot_thread_id,
            createdAt: thread.created_at,
            lastMessage: thread.last_message || 'New Chat',
            title: thread.title || 'New Chat',
            isPinned: thread.is_pinned || false,
            thread_type: thread.thread_type || 'private',
            userId: thread.user_id
          }))
          .sort((a, b) => {
            // Sort by pinned status first, then by date
            if (a.isPinned && !b.isPinned) return -1;
            if (!a.isPinned && b.isPinned) return 1;
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        setThreads(sortedThreads);
      } catch (error) {
        console.error('Error fetching threads:', error);
      }
    };

    fetchThreads();
  }, [token]);

  const handleThreadSelect = (selectedThreadId, updatedThreads = null) => {
    localStorage.setItem('threadId', selectedThreadId);
    setAllThreadMessages([]); // Clear current messages
    fetchInitialMessages(selectedThreadId);
    
    // Update threads if provided
    if (updatedThreads) {
      setThreads(updatedThreads);
    }
    
    // Rejoin websocket with new thread
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ 
        type: 'join_thread', 
        threadId: selectedThreadId,
        userId: userId 
      }));
    }
  };

  // Add this helper function to format the message content
  const formatMessageContent = (content) => {
    if (!content) return '';
    
    // Split content by SQL code blocks
    const parts = content.split(/(```sql[\s\S]*?```)/g);
    
    return parts.map((part, index) => {
      // Check if this part is a SQL code block
      if (part.startsWith('```sql')) {
        const sqlCode = part.replace(/```sql\n?/, '').replace(/```$/, '').trim();
        return formatCodeBlock(sqlCode, 'SQL');
      }
      
      // For regular text content
      return (
        <div key={index}>
          {formatParagraphContent(part)}
        </div>
      );
    });
  };

  // Helper function to format paragraphs and lists
  const formatParagraphContent = (content) => {
    return content.split('\n').map((line, index) => {
      line = line.trim();
      if (!line) return null;

      // Check for numbered lists (1., 2., etc.)
      if (/^\d+\.\s/.test(line)) {
        return (
          <Typography key={index} component="div" sx={{ ml: 2, mb: 1 }}>
            {formatBoldText(line)}
          </Typography>
        );
      }
      
      // Check for bullet points
      if (line.startsWith('-')) {
        return (
          <Typography key={index} component="div" sx={{ ml: 3, mb: 0.5 }}>
            {formatBoldText(line)}
          </Typography>
        );
      }
      
      // Regular paragraph
      return (
        <Typography key={index} component="div" sx={{ mb: 1 }}>
          {formatBoldText(line)}
        </Typography>
      );
    });
  };

  // Add this new helper function to handle bold text formatting
  const formatBoldText = (text) => {
    // Split by ** but keep the delimiters
    const parts = text.split(/(\*\*.*?\*\*)/g);
    
    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        // Remove the ** and wrap in strong tag
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      return part;
    });
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const uploadFile = async () => {
    if (!selectedFile) return null;

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('threadId', threadId);

    try {
      const response = await fetch(`${BASE_URL}/api/chat/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Failed to upload file');
      }

      const data = await response.json();
      setSelectedFile(null); // Clear selected file after upload
      return data;
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
      return null;
    }
  };

  const [previews, setPreviews] = useState({});
  const [previewErrors, setPreviewErrors] = useState({});

  // Update the renderPreviewContent function to handle JSON type
  const renderPreviewContent = (preview, fileType) => {
    if (!preview) return null;

    switch (fileType) {
      case 'text':
      case 'pdf':
      case 'docx':
        return (
          <Paper
            sx={{
              p: 2,
              maxHeight: '200px',
              overflow: 'auto',
              backgroundColor: theme => 
                theme.palette.mode === 'light' 
                  ? 'rgba(0, 0, 0, 0.02)' 
                  : 'rgba(255, 255, 255, 0.05)',
              fontFamily: 'monospace',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word'
            }}
          >
            <Typography variant="body2">
              {preview}
            </Typography>
          </Paper>
        );

      case 'csv':
        return (
          <Paper 
            sx={{ 
              p: 2, 
              maxHeight: '300px', 
              overflow: 'auto'
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  {preview.headers?.map((header, i) => (
                    <TableCell key={i}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {preview.preview?.map((row, i) => (
                  <TableRow key={i}>
                    {Object.values(row).map((cell, j) => (
                      <TableCell key={j}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        );

      case 'json':
        try {
          const jsonData = typeof preview === 'string' ? JSON.parse(preview) : preview;
          // Get all unique keys from the JSON data if it's an array
          const headers = Array.isArray(jsonData) 
            ? [...new Set(jsonData.flatMap(obj => Object.keys(obj)))]
            : Object.keys(jsonData);
          
          return (
            <Paper 
              sx={{ 
                p: 2, 
                maxHeight: '300px', 
                overflow: 'auto',
                backgroundColor: theme => 
                  theme.palette.mode === 'light' 
                    ? 'rgba(255, 255, 255, 0.9)'
                    : 'rgba(18, 18, 18, 0.8)',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              }}
            >
              <Table size="small" sx={{ minWidth: 300 }}>
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell 
                        key={i}
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: theme => 
                            theme.palette.mode === 'light'
                              ? 'rgba(0, 0, 0, 0.04)'
                              : 'rgba(255, 255, 255, 0.04)',
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(jsonData) 
                    ? jsonData.map((row, i) => (
                        <TableRow 
                          key={i}
                          sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: theme =>
                                theme.palette.mode === 'light'
                                  ? 'rgba(0, 0, 0, 0.02)'
                                  : 'rgba(255, 255, 255, 0.02)',
                            },
                          }}
                        >
                          {headers.map((header, j) => (
                            <TableCell key={j}>
                              {typeof row[header] === 'object' 
                                ? JSON.stringify(row[header])
                                : row[header]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    : (
                        <TableRow>
                          {headers.map((header, j) => (
                            <TableCell key={j}>
                              {typeof jsonData[header] === 'object'
                                ? JSON.stringify(jsonData[header])
                                : jsonData[header]}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                  }
                </TableBody>
              </Table>
            </Paper>
          );
        } catch (error) {
          console.error('Error rendering JSON:', error);
          return (
            <Typography color="error">
              Error displaying JSON data
            </Typography>
          );
        }

      default:
        return null;
    }
  };

  // Update the fetchPreview function to handle the response properly
  const fetchPreview = async (fileId, fileName) => {
    try {
      const response = await fetch(`${BASE_URL}/api/file/preview/${fileId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch preview: ${response.statusText}`);
      }

      const contentType = response.headers.get('content-type');
      
      if (contentType.includes('image')) {
        // Handle image preview
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setPreviews(prev => ({
          ...prev,
          [fileId]: {
            type: 'image',
            content: imageUrl
          }
        }));
      } else if (contentType.includes('application/json')) {
        // Handle text, PDF, DOCX, CSV previews
        const data = await response.json();
        setPreviews(prev => ({
          ...prev,
          [fileId]: {
            type: data.type,
            content: data.preview,
            headers: data.headers, // For CSV
            totalPages: data.totalPages // For PDF
          }
        }));
      }
    } catch (error) {
      console.error('Preview error:', error);
      setPreviewErrors(prev => ({
        ...prev,
        [fileId]: error.message
      }));
    }
  };

  // Add state for tracking which previews are open
  const [openPreviews, setOpenPreviews] = useState(new Set());

  // Add toggle function
  const togglePreview = (fileId) => {
    setOpenPreviews(prev => {
      const newSet = new Set(prev);
      if (newSet.has(fileId)) {
        newSet.delete(fileId);
      } else {
        newSet.add(fileId);
        // Fetch preview if not already fetched
        if (!previews[fileId]) {
          fetchPreview(fileId);
        }
      }
      return newSet;
    });
  };

  // Update the isJsonContent function to be more permissive and add logging
  const isJsonContent = (contentItem) => {
    return contentItem.type === 'non_image' && 
           (contentItem.file?.type === 'application/json' || 
            contentItem.file?.name?.toLowerCase().endsWith('.json'));
  };

  // Add logging in renderMessageContent
  const renderMessageContent = (contentItem, contentIndex) => {
    const isJson = isJsonContent(contentItem);
    
    return (
      <Box 
        key={contentIndex} 
        sx={{ marginBottom: '16px', position: 'relative', width: '100%', maxWidth: '100%' }}
      >
        {contentItem.type === 'text' && (
          <div style={{ 
            display: 'block',
            // Add these properties to prevent resize loops
            width: '100%',
            maxWidth: '100%',
            wordBreak: 'break-word'
          }}>
            {formatMessageContent(cleanMessage(contentItem.text.value))}
          </div>
        )}
        {(contentItem.type === 'non_image' || contentItem.type === 'image') && contentItem.file && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '8px',
                padding: '12px',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? 'rgba(0, 0, 0, 0.04)'
                    : 'rgba(255, 255, 255, 0.04)',
                borderRadius: '8px',
                width: '100%',
                maxWidth: '100%',
                boxSizing: 'border-box'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <UploadFileIcon />
                <Typography variant="body2">
                  {contentItem.file.name}
                </Typography>
              </Box>
              {contentItem.file.file_id && (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {/* Preview button */}
                  <Tooltip title={openPreviews.has(contentItem.file.file_id) ? "Hide Preview" : "Show Preview"}>
                    <IconButton
                      onClick={() => togglePreview(contentItem.file.file_id)}
                      size="small"
                      sx={{
                        color: 'primary.main',
                        transform: openPreviews.has(contentItem.file.file_id) ? 'rotate(180deg)' : 'none',
                        transition: 'transform 0.2s ease-in-out',
                      }}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Tooltip>

                  {/* CSV Download button */}
                  {isJson && (
                    <Tooltip title="Download as CSV">
                      <IconButton
                        onClick={() => handleCsvDownload(contentItem.file.file_id, contentItem.file.name)}
                        size="small"
                        sx={{ 
                          color: 'primary.main',
                        }}
                      >
                        <TableViewIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  {/* Regular download button - Hide if JSON */}
                  {!isJson && (
                    <Tooltip title="Download">
                      <IconButton
                        onClick={() => handleDownload(contentItem.file.file_id, contentItem.file.name)}
                        size="small"
                        sx={{ color: 'primary.main' }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )}
            </Box>
            {openPreviews.has(contentItem.file.file_id) && (
              <Box
                sx={{
                  marginTop: 2,
                  borderRadius: 1,
                  overflow: 'hidden',
                  maxWidth: '100%',
                  animation: `${fadeIn} 0.3s ease-in-out`,
                  // Add these properties to prevent resize loops
                  position: 'relative',
                  boxSizing: 'border-box'
                }}
              >
                {previews[contentItem.file.file_id]?.type === 'image' ? (
                  <img 
                    src={previews[contentItem.file.file_id].content}
                    alt="Preview"
                    style={{
                      width: '100%',
                      maxWidth: '300px',
                      height: 'auto',
                      display: 'block'
                    }}
                  />
                ) : previews[contentItem.file.file_id] ? (
                  renderPreviewContent(
                    previews[contentItem.file.file_id].content,
                    previews[contentItem.file.file_id].type
                  )
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress size={24} />
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    );
  };

  useEffect(() => {
    return () => {
      // Cleanup object URLs when component unmounts
      Object.values(previews).forEach(preview => {
        if (preview.type === 'image') {
          URL.revokeObjectURL(preview.content);
        }
      });
    };
  }, [previews]);

  const handleDownload = async (fileId, fileName) => {
    try {
      const response = await fetch(`${BASE_URL}/api/chat/download/${fileId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Download failed');
      }

      // Create a blob from the response
      const blob = await response.blob();
      
      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName; // Set the file name
      
      // Append to body, click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the temporary URL
      window.URL.revokeObjectURL(url);

      // Show success message
      toast.success('File downloaded successfully');
      
    } catch (error) {
      console.error('Download error:', error);
      toast.error('Failed to download file');
    }
  };

  // Add this useEffect to scroll to bottom when messages are loaded
  useEffect(() => {
    if (allThreadMessages.length > 0) {
      scrollToBottom(true);
    }
  }, [allThreadMessages.length]); // Dependency on messages length

  // Add this new helper function to format SQL code blocks with collapsible feature
  const formatCodeBlock = (code, language) => {
    return <CollapsibleCodeBlock code={code} language={language} />;
  };

  // Add the CSV download handler
  const handleCsvDownload = async (fileId, fileName) => {
    try {
      const response = await fetch(`${BASE_URL}/api/chat/download-csv/${fileId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('CSV download failed');
      }

      // Create a blob from the response
      const blob = await response.blob();
      
      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName.replace('.json', '.csv'); // Change extension to .csv
      
      // Append to body, click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the temporary URL
      window.URL.revokeObjectURL(url);

      toast.success('CSV downloaded successfully');
      
    } catch (error) {
      console.error('CSV download error:', error);
      toast.error('Failed to download CSV');
    }
  };

  return (
    <Box sx={{
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? alpha(theme.palette.primary.light, 0.05)
          : theme.palette.grey[900],
      flexGrow: 1,
      minHeight: '100vh',
      overflow: 'auto',
      backgroundImage: (theme) => 
        theme.palette.mode === 'light'
          ? 'linear-gradient(120deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.6) 100%)'
          : 'linear-gradient(120deg, rgba(18,18,18,0.3) 0%, rgba(18,18,18,0.6) 100%)',
    }}>
      <CssBaseline />
      <Container 
        maxWidth="lg" 
        sx={{ 
          height: '100vh', 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <ChatHistory 
          threads={threads} 
          onThreadSelect={handleThreadSelect}
          currentThreadId={threadId}
          ws={ws}
        />
        
        <Paper 
          elevation={6}
          sx={{
            marginTop: '60px',
            width: '80vh',
            height: '85vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '16px',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? 'rgba(255, 255, 255, 0.9)'
                : 'rgba(18, 18, 18, 0.8)',
            backdropFilter: 'blur(10px)',
            border: '1px solid',
            borderColor: (theme) =>
              theme.palette.mode === 'light'
                ? 'rgba(255, 255, 255, 0.5)'
                : 'rgba(255, 255, 255, 0.1)',
            boxShadow: (theme) =>
              theme.palette.mode === 'light'
                ? '0 8px 32px rgba(0, 0, 0, 0.1)'
                : '0 8px 32px rgba(0, 0, 0, 0.3)',
          }}
        >
          {/* Add Title Bar */}
          <Box
            sx={{
              padding: '16px 20px',
              borderBottom: '1px solid',
              borderColor: (theme) =>
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.1)'
                  : 'rgba(255, 255, 255, 0.1)',
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.9)'
                  : 'rgba(18, 18, 18, 0.8)',
              backdropFilter: 'blur(10px)',
              borderRadius: '16px 16px 0 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '10px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: (theme) => theme.palette.mode === 'light' ? '#2d3436' : '#f8f9fa',
                fontSize: '1.1rem',
              }}
            >
              {threads.find(t => t.threadId === threadId)?.title || 'New Chat'}
            </Typography>

            {/* New Chat Button with Tooltip */}
            <Tooltip title="New Chat">
              <IconButton
                onClick={handleClearChat}
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                  },
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Messages container with flex layout */}
          <Box sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            flexDirection: 'column',
            overflow: 'hidden'
          }}>
            <List 
              ref={listRef} 
              sx={{ 
                flexGrow: 1,
                overflow: 'auto',
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                overscrollBehavior: 'contain',
                scrollBehavior: 'smooth',
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: (theme) =>
                    theme.palette.mode === 'light'
                      ? alpha(theme.palette.primary.main, 0.2)
                      : alpha(theme.palette.primary.main, 0.3),
                  borderRadius: '10px',
                },
              }}
            >
              <Box sx={{ display: 'flex' }}>
                {isPreviousMessagesLoading && <CircularProgress />}
              </Box>
              
              {/* Messages rendering */}
              <Box sx={{ flexGrow: 1 }}>
                {(() => {
                  let currentDate = null;
                  return allThreadMessages.map((msg, index) => {
                    const messageDate = new Date(msg.created_at * 1000).toDateString();
                    let dateDivider = null;

                    if (messageDate !== currentDate) {
                      currentDate = messageDate;
                      dateDivider = (
                        <ListItem 
                          key={`divider-${msg.created_at}`}
                          sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            padding: '10px 0' 
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'grey.400' }} />
                            <Typography 
                              variant="caption" 
                              sx={{ 
                                margin: '0 10px', 
                                padding: '2px 10px', 
                                backgroundColor: 'grey.200', 
                                borderRadius: '10px',
                                color: 'text.secondary'
                              }}
                            >
                              {formatDate(msg.created_at)}
                            </Typography>
                            <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'grey.400' }} />
                          </Box>
                        </ListItem>
                      );
                    }

                    return (
                      <React.Fragment key={msg.id}>
                        {dateDivider}
                        <ListItem 
                          sx={{ 
                            display: 'flex', 
                            justifyContent: msg.role === 'assistant' ? 'flex-start' : 'flex-end',
                            padding: '8px 0',
                          }}
                        >
                          <Paper
                            sx={{
                              padding: '12px 16px',
                              maxWidth: '80%',
                              background: msg.role === 'assistant'
                                ? (theme) => theme.palette.mode === 'light'
                                  ? 'linear-gradient(135deg, rgba(236, 242, 254, 0.9), rgba(236, 242, 254, 0.7))'
                                  : 'linear-gradient(135deg, rgba(44, 62, 80, 0.95), rgba(44, 62, 80, 0.85))'
                                : (theme) => theme.palette.mode === 'light'
                                  ? 'linear-gradient(135deg, rgba(248, 250, 252, 0.9), rgba(248, 250, 252, 0.7))'
                                  : 'linear-gradient(135deg, rgba(71, 85, 105, 0.3), rgba(71, 85, 105, 0.2))',
                              borderRadius: msg.role === 'assistant' ? '0 16px 16px 16px' : '16px 0 16px 16px',
                              boxShadow: (theme) =>
                                theme.palette.mode === 'light'
                                  ? '0 2px 12px rgba(0, 0, 0, 0.08)'
                                  : '0 2px 12px rgba(0, 0, 0, 0.2)',
                              border: '1px solid',
                              borderColor: msg.role === 'assistant'
                                ? (theme) => theme.palette.mode === 'light'
                                  ? 'rgba(187, 205, 251, 0.3)'
                                  : 'rgba(44, 62, 80, 0.5)'
                                : (theme) => theme.palette.mode === 'light'
                                  ? 'rgba(71, 85, 105, 0.1)'
                                  : 'rgba(71, 85, 105, 0.2)',
                              '&:hover': {
                                boxShadow: (theme) =>
                                  theme.palette.mode === 'light'
                                    ? '0 4px 16px rgba(0, 0, 0, 0.1)'
                                    : '0 4px 16px rgba(0, 0, 0, 0.3)',
                              },
                              animation: msg.temporary ? 'none' : `${fadeIn} 0.3s ease-in-out`,
                              '& .streaming-text': {
                                display: 'inline-block',
                                opacity: 1,
                                transition: 'opacity 0.2s ease-in-out'
                              }
                            }}
                            elevation={0}
                            transform='translateZ(0)'
                            backfaceVisibility='hidden'
                            perspective={1000}
                            maxWidth='80%'
                            width='fit-content'
                          >
                            <div>
                              {msg.role === 'assistant'
                                ? formatNameAndTime('Heartee.AI', null, msg.created_at, true)
                                : formatNameAndTime(msg.first_name, msg.last_name, msg.created_at, false)
                              } 
                              {Array.isArray(msg.content) && msg.content.map((contentItem, contentIndex) => 
                                renderMessageContent(contentItem, contentIndex)
                              )}
                              {/* Add streaming content if this is the last assistant message */}
                              {isStreaming && 
                               index === allThreadMessages.length - 1 && 
                               msg.role === 'assistant' && (
                                <div style={{ display: 'block' }}>
                                  {formatMessageContent(streamingMessage)}
                                  <span 
                                    className="cursor-blink" 
                                    style={{ 
                                      marginLeft: '2px',
                                      borderLeft: '2px solid currentColor',
                                      animation: 'blink 1s step-end infinite'
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </Paper>
                        </ListItem>
                      </React.Fragment>
                    );
                  });
                })()}
              </Box>

              {/* Loading and typing indicators at bottom */}
              <Box sx={{ 
                marginTop: 'auto',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                {isNewMessageLoading && (
                  <LinearProgress 
                    sx={{ 
                      width: '93%', 
                      height: '5px', 
                      margin: '10px 0'
                    }} 
                  />
                )}
                <div ref={messagesEndRef} />
              </Box>
            </List>
          </Box>

          {/* Typing indicator */}
          <Box 
            sx={{ 
              padding: '8px 16px', 
              minHeight: '24px', 
              color: 'text.secondary',
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? alpha(theme.palette.background.paper, 0.8)
                  : alpha(theme.palette.background.paper, 0.1),
            }}
          >
            {typingUsersNames.length > 0 && (
              <Typography 
                variant="caption"
                sx={{ 
                  opacity: 0.8,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {typingUsersNames.join(', ')} {typingUsersNames.length === 1 ? 'is' : 'are'} typing...
              </Typography>
            )}
          </Box>

          {/* Input box */}
          <Box
            component="form"
            sx={{
              display: 'flex',
              padding: '16px',
              gap: 1.5,
              borderTop: '1px solid',
              borderColor: 'divider',
              backgroundColor: 'background.paper',
            }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSendMessage();
            }}
          >
            {/* Attach File Button - Moved to left */}
            <input
              id="file-upload"
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              style={{ display: 'none' }}
              accept="image/*,.pdf,.doc,.docx,.txt"
              multiple={false} // Explicitly disable multiple file selection
            />
            <label htmlFor="file-upload">
              <Tooltip 
                title={selectedFile ? `Selected: ${selectedFile.name}` : 'Attach File'} 
                placement="top"
              >
                <Button
                  component="span"
                  variant="contained"
                  disabled={selectedFile !== null}
                  sx={theme => ({
                    minWidth: '45px',
                    height: '45px',
                    borderRadius: '10px',
                    backgroundColor: selectedFile 
                      ? alpha(theme.palette.primary.main, 0.1)
                      : theme.palette.primary.main,
                    color: selectedFile 
                      ? theme.palette.primary.main
                      : theme.palette.common.white,
                    border: selectedFile 
                      ? `2px solid ${theme.palette.primary.main}`
                      : 'none',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: selectedFile 
                        ? alpha(theme.palette.primary.main, 0.2)
                        : theme.palette.primary.dark,
                      boxShadow: 'none',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      color: theme.palette.primary.main,
                      border: `2px solid ${theme.palette.primary.main}`,
                      opacity: 1,
                    },
                    transition: 'all 0.2s ease-in-out',
                  })}
                >
                  {selectedFile ? (
                    <CheckIcon sx={{ 
                      fontSize: '1.2rem',
                      animation: `${fadeIn} 0.2s ease-in-out`
                    }} />
                  ) : (
                    <UploadFileIcon sx={{ fontSize: '1.2rem' }} />
                  )}
                </Button>
              </Tooltip>
            </label>

            {/* Text Input - Now between attach and send buttons */}
            <Box sx={{ display: 'flex', flexGrow: 1, position: 'relative' }}>
              {MessageInput}
              {/* Selected file indicator remains the same */}
              {selectedFile && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '100%',
                    left: 0,
                    right: 0,
                    mb: 1,
                    p: 1,
                    borderRadius: 1,
                    backgroundColor: theme => 
                      theme.palette.mode === 'light'
                        ? 'rgba(255, 255, 255, 0.9)'
                        : 'rgba(0, 0, 0, 0.6)',
                    backdropFilter: 'blur(4px)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    animation: `${fadeIn} 0.3s ease-in-out`,
                  }}
                >
                  <UploadFileIcon color="primary" sx={{ fontSize: 20 }} />
                  <Typography variant="body2" sx={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {selectedFile.name}
                  </Typography>
                  <IconButton 
                    size="small" 
                    onClick={() => setSelectedFile(null)}
                    sx={{ p: 0.5 }}
                  >
                    <CloseIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </Box>
              )}
            </Box>

            {/* Send Buttons - Now on the right */}
            <Tooltip title="Send to AI Assistant" placement="top">
              <Button
                variant="contained"
                onClick={handleSendMessage}
               // disabled={!currentUserMessageText && !selectedFile}
                sx={{
                  minWidth: '45px',
                  height: '45px',
                  borderRadius: '10px',
                  backgroundColor: theme => theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme => theme.palette.primary.dark,
                  },
                }}
              >
                <SmartToyIcon sx={{ fontSize: '1.2rem' }} />
              </Button>
            </Tooltip>

            <Tooltip title="Send to Chat" placement="top">
              <Button
                variant="contained"
                onClick={handleSendMessageWithoutAI}
               // disabled={!currentUserMessageText && !selectedFile}
                sx={{
                  minWidth: '45px',
                  height: '45px',
                  borderRadius: '10px',
                  backgroundColor: theme => theme.palette.secondary.main,
                  '&:hover': {
                    backgroundColor: theme => theme.palette.secondary.dark,
                  },
                }}
              >
                <ChatIcon sx={{ fontSize: '1.2rem' }} />
              </Button>
            </Tooltip>
          </Box>
        </Paper>
      </Container>
    </Box>
  );

}

export default ChatAI;