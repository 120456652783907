import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { format, parseISO } from 'date-fns';
import Divider from '@mui/material/Divider';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { keyframes } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { getToken, getUserId } from '../../auth/auth.js';
import { BASE_URL } from '../../Constants';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PushPinIcon from '@mui/icons-material/PushPin';
import ArchiveIcon from '@mui/icons-material/Archive';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import Badge from '@mui/material/Badge';
import LockIcon from '@mui/icons-material/Lock';
import PublicIcon from '@mui/icons-material/Public';
import Tooltip from '@mui/material/Tooltip';


// Define animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const slideIndicator = keyframes`
  from { transform: scaleX(0.7); opacity: 0.8; }
  to { transform: scaleX(1); opacity: 1; }
`;

const StyledBadge = ({ count }) => (
  <Box
    sx={{
      backgroundColor: 'primary.main',
      color: 'white',
      borderRadius: '10px',
      padding: '2px 6px',
      fontSize: '0.7rem',
      fontWeight: 600,
      minWidth: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      ml: 1,
      transition: 'all 0.2s ease',
      animation: `${fadeIn} 0.3s ease-out`,
    }}
  >
    {count}
  </Box>
);

function ChatHistory({ threads, onThreadSelect, currentThreadId, ws }) {
  const [editingThreadId, setEditingThreadId] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedThread, setSelectedThread] = useState(null);
  const token = getToken();
  const [currentTab, setCurrentTab] = useState('active');
  const [archivedThreads, setArchivedThreads] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({});

  const fetchUnreadCounts = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/chat/unread-counts`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch unread counts');
      
      const data = await response.json();
      console.log('Raw unread counts data:', data); // Debug log
      
      const countsMap = data.counts.reduce((acc, item) => {
        acc[item.threadId] = parseInt(item.unreadCount);
        return acc;
      }, {});
      
      console.log('Processed unread counts:', countsMap); // Debug log
      setUnreadCounts(countsMap);
    } catch (error) {
      console.error('Error fetching unread counts:', error);
    }
  };

  useEffect(() => {
    fetchUnreadCounts();
  }, [threads]);

  useEffect(() => {
    if (!ws) return;

    const handleWebSocketMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        
        if (data.type === 'unread_counts') {
          const countsMap = data.counts.reduce((acc, item) => {
            acc[item.threadId] = item.unreadCount;
            return acc;
          }, {});
          setUnreadCounts(countsMap);
        } else if (data.type === 'messages_read') {
          setUnreadCounts(prev => ({
            ...prev,
            [data.threadId]: 0
          }));
        }
      } catch (error) {
        console.error('Error handling WebSocket message:', error);
      }
    };

    ws.addEventListener('message', handleWebSocketMessage);
    
    if (ws.readyState === WebSocket.OPEN) {
      fetchUnreadCounts();
    }

    return () => {
      ws.removeEventListener('message', handleWebSocketMessage);
    };
  }, [ws]);

  useEffect(() => {
    const fetchArchivedThreads = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/archivedthreads`, {
          headers: {
            'Authorization': `Bearer ${getToken()}`
          }
        });
        
        if (!response.ok) throw new Error('Failed to fetch archived threads');
        
        const data = await response.json();
        const mappedThreads = data.threads.map(thread => ({
          ...thread,
          thread_type: thread.visibility || 'private'
        }));
        setArchivedThreads(mappedThreads);
      } catch (error) {
        console.error('Error fetching archived threads:', error);
        toast.error('Failed to load archived chats');
      }
    };

    fetchArchivedThreads();
  }, []);

  const handleEditClick = (thread) => {
    setEditingThreadId(thread.threadId);
    setEditTitle(thread.title);
  };

  const handleCancelEdit = () => {
    setEditingThreadId(null);
    setEditTitle('');
  };

  const handleSaveTitle = async (threadId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/updatethreadtitle/${threadId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ title: editTitle })
      });

      const data = await response.json();
      
      if (data.success) {
        const updatedThreads = threads.map(thread => 
          thread.threadId === threadId 
            ? { 
                ...thread, 
                title: editTitle,
                thread_type: thread.visibility || thread.thread_type || 'private'
              } 
            : thread
        );
        onThreadSelect(threadId, updatedThreads);
        setEditingThreadId(null);
        toast.success('Title updated successfully');
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error updating title:', error);
      toast.error('Failed to update title');
    }
  };

  const formatDate = (timestamp) => {
    const date = typeof timestamp === 'number' 
      ? new Date(timestamp * 1000)
      : parseISO(timestamp);
    return format(date, 'MMM d, yyyy h:mm a');
  };

  const handleMenuClick = (event, thread) => {
    event.preventDefault();
    event.stopPropagation();
    if (anchorEl) {
      handleMenuClose();
    } else {
      setAnchorEl(event.currentTarget);
      setSelectedThread(thread);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedThread(null);
  };

  const handlePinConversation = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/pinthread/${selectedThread.threadId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to pin/unpin thread');

      const updatedThreads = threads.map(t => 
        t.threadId === selectedThread.threadId 
          ? { ...t, isPinned: !t.isPinned }
          : t
      ).sort((a, b) => {
        if (a.isPinned && !b.isPinned) return -1;
        if (!a.isPinned && b.isPinned) return 1;
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      onThreadSelect(currentThreadId, updatedThreads);
      handleMenuClose();
    } catch (error) {
      console.error('Error pinning/unpinning thread:', error);
    }
  };

  const handleArchive = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/archivethread/${selectedThread.threadId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to archive thread');
      }

      const updatedThreads = threads.filter(t => t.threadId !== selectedThread.threadId);

      setArchivedThreads(prev => [...prev, selectedThread]);

      if (selectedThread.threadId === currentThreadId && updatedThreads.length > 0) {
        onThreadSelect(updatedThreads[0].threadId, updatedThreads);
      } else {
        onThreadSelect(currentThreadId, updatedThreads);
      }

      handleMenuClose();
    } catch (error) {
      console.error('Error archiving thread:', error);
    }
  };

  const handleRestore = async (event, thread) => {
    event.stopPropagation();
    try {
      const response = await fetch(`${BASE_URL}/api/restorethread/${thread.threadId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to restore thread');

      setArchivedThreads(prev => prev.filter(t => t.threadId !== thread.threadId));
      
      const threadsResponse = await fetch(`${BASE_URL}/api/openai/threads`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      });
      
      if (!threadsResponse.ok) throw new Error('Failed to fetch updated threads');
      
      const data = await threadsResponse.json();
      const sortedThreads = data.threads
        .map(t => ({
          threadId: t.chatbot_thread_id,
          createdAt: t.created_at,
          lastMessage: t.last_message || 'New Chat',
          title: t.title || 'New Chat',
          isPinned: t.is_pinned || false,
          user_id: t.user_id,
          thread_type: t.thread_type || 'private'
        }))
        .sort((a, b) => {
          if (a.isPinned && !b.isPinned) return -1;
          if (!a.isPinned && b.isPinned) return 1;
          const dateA = typeof a.createdAt === 'string' ? Date.parse(a.createdAt) : a.createdAt * 1000;
          const dateB = typeof b.createdAt === 'string' ? Date.parse(b.createdAt) : b.createdAt * 1000;
          return dateB - dateA;
        });

      onThreadSelect(thread.threadId, sortedThreads);
      setCurrentTab('active');
    } catch (error) {
      console.error('Error restoring thread:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const menuItems = currentTab === 'active' ? (
    <>
      <MenuItem onClick={handlePinConversation}>
        <PushPinIcon sx={{ 
          mr: 1.5, 
          fontSize: 18,
          transform: selectedThread?.isPinned ? 'rotate(45deg)' : 'none'
        }} />
        <Typography variant="body2">
          {selectedThread?.isPinned ? 'Unpin' : 'Pin'}
        </Typography>
      </MenuItem>
      
      <MenuItem onClick={() => {
        console.log('Current thread type:', selectedThread.thread_type);
        const newVisibility = selectedThread.thread_type === 'private' ? 'public' : 'private';
        console.log('Changing visibility to:', newVisibility);
        
        fetch(`${BASE_URL}/api/threads/${selectedThread.threadId}/visibility`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
          body: JSON.stringify({ thread_type: newVisibility })
        })
        .then(response => {
          if (!response.ok) throw new Error('Failed to update visibility');
          return response.json();
        })
        .then(() => {
          const updatedThreads = threads.map(t => 
            t.threadId === selectedThread.threadId 
              ? { ...t, thread_type: newVisibility }
              : t
          );
          onThreadSelect(currentThreadId, updatedThreads);
          handleMenuClose();
          toast.success(`Thread is now ${newVisibility}`);
        })
        .catch(error => {
          console.error('Error updating thread visibility:', error);
          toast.error('Failed to update thread visibility');
        });
      }}>
        {selectedThread && (  // Add null check for selectedThread
          <>
            {selectedThread.thread_type === 'private' ? (
              <>
                <PublicIcon sx={{ mr: 1.5, fontSize: 18 }} />
                <Typography variant="body2">Make Public</Typography>
              </>
            ) : (
              <>
                <LockIcon sx={{ mr: 1.5, fontSize: 18 }} />
                <Typography variant="body2">Make Private</Typography>
              </>
            )}
          </>
        )}
      </MenuItem>

      <MenuItem onClick={() => {
        handleEditClick(selectedThread);
        handleMenuClose();
      }}>
        <EditIcon sx={{ mr: 1.5, fontSize: 18 }} />
        <Typography variant="body2">Edit Name</Typography>
      </MenuItem>

      <MenuItem onClick={handleArchive}>
        <ArchiveIcon sx={{ mr: 1.5, fontSize: 18 }} />
        <Typography variant="body2">Archive</Typography>
      </MenuItem>
    </>
  ) : null;

  const handleVisibilityToggle = async (e, thread) => {
    e.stopPropagation();
    try {
      const currentVisibility = thread.thread_type || 'private';
      const newVisibility = currentVisibility === 'private' ? 'public' : 'private';
      
      const response = await fetch(`${BASE_URL}/api/threads/${thread.threadId}/visibility`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify({ thread_type: newVisibility })
      });

      if (!response.ok) {
        throw new Error('Failed to update visibility');
      }

      const updatedThreads = threads.map(t => 
        t.threadId === thread.threadId 
          ? { ...t, thread_type: newVisibility }
          : t
      );
      
      onThreadSelect(currentThreadId, updatedThreads);
      toast.success(`Thread is now ${newVisibility}`);
    } catch (error) {
      console.error('Error updating thread visibility:', error);
      toast.error('Failed to update thread visibility');
    }
  };

  const handleThreadSelect = (threadId) => {
    if (!editingThreadId && currentTab === 'active') {
      onThreadSelect(threadId);
    }
  };

  const renderThreadItem = (thread, index) => {
    const isOwner = parseInt(thread.user_id) === parseInt(getUserId());
    const currentVisibility = thread.thread_type || 'private';
    const shouldShowUnreadCount = currentVisibility === 'public' || isOwner;
    const unreadCount = shouldShowUnreadCount ? (unreadCounts[thread.threadId] || 0) : 0;

    return (
      <ListItem 
        button 
        onClick={() => handleThreadSelect(thread.threadId)}
        disableRipple={editingThreadId === thread.threadId}
        sx={{
          borderRadius: '12px',
          mb: 0.5,
          animation: `${fadeIn} 0.2s ease-out`,
          animationFillMode: 'backwards',
          animationDelay: `${index * 0.05}s`,
          backgroundColor: currentThreadId === thread.threadId 
            ? (theme) => theme.palette.mode === 'light' 
              ? 'rgba(25, 118, 210, 0.1)' 
              : 'rgba(144, 202, 249, 0.1)'
            : 'transparent',
          '&:hover': {
            backgroundColor: (theme) => 
              editingThreadId === thread.threadId
                ? 'transparent'
                : theme.palette.mode === 'light' 
                  ? 'rgba(25, 118, 210, 0.05)' 
                  : 'rgba(144, 202, 249, 0.05)',
          },
          transition: 'background-color 0.15s ease',
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '4px',
            height: '100%',
            backgroundColor: 'primary.main',
            opacity: currentThreadId === thread.threadId ? 1 : 0,
            transition: 'opacity 0.15s ease',
          },
        }}
      >
        {editingThreadId === thread.threadId ? (
          // Edit mode
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <TextField
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
              size="small"
              fullWidth
              autoFocus
              onClick={(e) => e.stopPropagation()}
            />
            <IconButton 
              onClick={(e) => {
                e.stopPropagation();
                handleSaveTitle(thread.threadId);
              }}
              size="small"
            >
              <CheckIcon />
            </IconButton>
            <IconButton 
              onClick={(e) => {
                e.stopPropagation();
                handleCancelEdit();
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography 
                    component="span" 
                    variant="body1"
                    sx={{ 
                      flexGrow: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    {thread.isPinned && (
                      <PushPinIcon 
                        sx={{ 
                          fontSize: 16,
                          transform: 'rotate(45deg)',
                          color: 'primary.main',
                          flexShrink: 0
                        }} 
                      />
                    )}
                    {thread.title || 'New Chat'}
                  </Typography>
                  {unreadCount > 0 && shouldShowUnreadCount && (
                    <Badge
                      badgeContent={unreadCount}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          fontSize: '0.75rem',
                          height: '20px',
                          minWidth: '20px',
                          padding: '0 6px',
                          borderRadius: '10px',
                        },
                        mr: 1
                      }}
                    />
                  )}
                  {isOwner && (
                    <Tooltip title={`${currentVisibility === 'private' ? 'Private' : 'Public'} Thread`}>
                      <IconButton 
                        size="small"
                        sx={{ 
                          ml: 1,
                          color: theme => currentVisibility === 'private' 
                            ? theme.palette.text.secondary 
                            : theme.palette.primary.main
                        }}
                        onClick={(e) => handleVisibilityToggle(e, thread)}
                      >
                        {currentVisibility === 'private' ? (
                          <LockIcon fontSize="small" />
                        ) : (
                          <PublicIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              }
              secondary={
                <Typography
                  variant="caption"
                  sx={{
                    color: (theme) => theme.palette.mode === 'light' ? '#636e72' : '#b2bec3',
                    fontSize: '0.75rem',
                    mt: 0.5,
                  }}
                >
                  {formatDate(thread.createdAt)}
                </Typography>
              }
              sx={{
                ml: 1,
                wordWrap: 'break-word',
                '& .MuiTypography-root': {
                  whiteSpace: 'normal'
                }
              }}
            />
            {currentTab === 'active' ? (
              <IconButton 
                onClick={(e) => handleMenuClick(e, thread)}
                size="small"
                sx={{ pointerEvents: 'auto' }}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
              >
                <MoreVertIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={(e) => handleRestore(e, thread)}
                size="small"
                sx={{
                  transition: 'transform 0.2s ease',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    color: 'primary.main'
                  }
                }}
              >
                <UnarchiveIcon sx={{ fontSize: 20 }} />
              </IconButton>
            )}
          </>
        )}
      </ListItem>
    );
  };

  return (
    <Paper 
      elevation={6}
      sx={{ 
        width: '300px',
        height: '85vh',
        marginRight: '20px',
        marginTop: '60px',
        overflow: 'hidden',
        backgroundColor: (theme) => 
          theme.palette.mode === 'light' 
            ? 'rgba(255, 255, 255, 0.9)' 
            : 'rgba(45, 52, 54, 0.9)',
        borderRadius: '16px',
        backdropFilter: 'blur(10px)',
        border: '1px solid',
        borderColor: (theme) => 
          theme.palette.mode === 'light' 
            ? 'rgba(255, 255, 255, 0.3)' 
            : 'rgba(255, 255, 255, 0.1)',
        position: 'relative',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          padding: '20px',
          borderBottom: '2px solid',
          borderColor: (theme) => 
            theme.palette.mode === 'light' 
              ? 'rgba(0, 0, 0, 0.1)' 
              : 'rgba(255, 255, 255, 0.1)',
          fontWeight: 700,
          color: (theme) => theme.palette.mode === 'light' ? '#2d3436' : '#f8f9fa',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? 'rgba(255, 255, 255, 0.9)'
              : 'rgba(45, 52, 54, 0.9)',
          backdropFilter: 'blur(10px)',
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}
      >
        <ChatBubbleOutlineIcon 
          sx={{ 
            animation: `${pulse} 2s infinite ease-in-out`,
            color: (theme) => theme.palette.primary.main,
          }} 
        />
        Chat History
      </Typography>

      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        variant="fullWidth"
        sx={{
          borderBottom: '1px solid',
          borderColor: (theme) => 
            theme.palette.mode === 'light' 
              ? 'rgba(0, 0, 0, 0.06)' 
              : 'rgba(255, 255, 255, 0.06)',
          '& .MuiTab-root': {
            minHeight: '56px',
            textTransform: 'none',
            fontSize: '0.95rem',
            fontWeight: 500,
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? 'rgba(25, 118, 210, 0.04)'
                  : 'rgba(144, 202, 249, 0.04)',
            },
            '&.Mui-selected': {
              fontWeight: 600,
            },
          },
          '& .MuiTabs-indicator': {
            height: '3px',
            borderRadius: '2px',
            animation: `${slideIndicator} 0.3s ease-out`,
            background: (theme) => 
              `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          },
        }}
      >
        <Tab 
          icon={
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              color: (theme) => 
                currentTab === 'active' 
                  ? theme.palette.primary.main 
                  : theme.palette.text.secondary,
            }}>
              <ChatBubbleOutlineOutlinedIcon 
                sx={{ 
                  fontSize: 20,
                  transition: 'transform 0.2s ease',
                  transform: currentTab === 'active' ? 'scale(1.1)' : 'scale(1)',
                }} 
              />
              <Typography 
                variant="body2"
                sx={{ 
                  fontWeight: currentTab === 'active' ? 600 : 400,
                }}
              >
                Active Chats
              </Typography>
            </Box>
          }
          value="active"
          sx={{ 
            opacity: currentTab === 'active' ? 1 : 0.7,
          }}
        />
        <Tab 
          icon={
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              color: (theme) => 
                currentTab === 'archived' 
                  ? theme.palette.primary.main 
                  : theme.palette.text.secondary,
            }}>
              <StyledBadge count={archivedThreads.length}>
                <ArchiveOutlinedIcon 
                  sx={{ 
                    fontSize: 20,
                    transition: 'transform 0.2s ease',
                    transform: currentTab === 'archived' ? 'scale(1.1)' : 'scale(1)',
                  }} 
                />
              </StyledBadge>
              <Typography 
                variant="body2"
                sx={{ 
                  fontWeight: currentTab === 'archived' ? 600 : 400,
                }}
              >
                Archived
              </Typography>
            </Box>
          }
          value="archived"
          sx={{ 
            opacity: currentTab === 'archived' ? 1 : 0.7,
          }}
        />
      </Tabs>

      <Box
        sx={{
          height: '2px',
          background: (theme) =>
            theme.palette.mode === 'light'
              ? 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.03) 50%, rgba(255,255,255,0) 100%)'
              : 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.03) 50%, rgba(0,0,0,0) 100%)',
          marginBottom: '8px',
        }}
      />

      <List 
        sx={{ 
          padding: '8px',
          height: 'calc(100% - 180px)',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: (theme) => 
              theme.palette.mode === 'light' 
                ? 'rgba(0, 0, 0, 0.2)' 
                : 'rgba(255, 255, 255, 0.2)',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: (theme) => 
              theme.palette.mode === 'light' 
                ? 'rgba(0, 0, 0, 0.3)' 
                : 'rgba(255, 255, 255, 0.3)',
          },
        }}
      >
        <Box
          sx={{
            paddingBottom: '16px',
            animation: `${fadeIn} 0.2s ease-out`,
          }}
        >
          {(currentTab === 'active' ? threads : archivedThreads).map((thread, index) => (
            <React.Fragment key={thread.threadId}>
              {renderThreadItem(thread, index)}
              {index < threads.length - 1 && (
                <Divider 
                  variant="middle" 
                  sx={{ 
                    my: 0.5,
                    opacity: 0.5,
                  }} 
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      </List>

      {Boolean(anchorEl) && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          onClick={(e) => e.stopPropagation()}
          slotProps={{
            paper: {
              sx: {
                minWidth: '180px',
                borderRadius: '8px',
                mt: 0.5,
                boxShadow: (theme) => 
                  theme.palette.mode === 'light'
                    ? '0 1px 4px rgba(0,0,0,0.02)'
                    : '0 1px 4px rgba(0,0,0,0.02)',
                backgroundColor: (theme) => 
                  theme.palette.mode === 'light'
                    ? '#ffffff'
                    : '#2d3436',
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {menuItems}
        </Menu>
      )}
    </Paper>
  );
}

export default React.memo(ChatHistory); 