import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { toast } from 'react-toastify';
import { getToken } from '../../auth/auth.js';
import sortData from '../common/sortColumns';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Pagination from '@mui/material/Pagination';

function YieldView() {
  const [flushData, setFlushData] = React.useState([]);
  const [avgYieldPerBlock, setAvgYieldPerBlock] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [sortField, setSortField] = React.useState('flush_date');
  const [selectedFarm, setSelectedFarm] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [limit] = React.useState(10); // Items per page
  const [farms, setFarms] = React.useState([]);
  const [sortedData, setSortedData] = React.useState([]);

  const [sortDirection, setSortDirection] = React.useState({
    num_of_blocks: 'desc',
    strain_name: 'desc',
    farm_name: 'desc',
    flush_number: 'desc',
    flush_weight: 'desc',
    inoculation_date: 'desc',
    cut_date: 'desc',
    flush_date: 'desc',
  }); 

  const navigate = useNavigate();
  const token = getToken(); 
 
  const fetchFlushData = () => {
    const farmParam = selectedFarm ? `&farmId=${selectedFarm}` : '';
    fetch(`${BASE_URL}/api/readflush?page=${page}&limit=${limit}${farmParam}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(response => {
      setFlushData(response.data);
      setTotalPages(response.pagination.totalPages);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  } 

  const fetchAvgYieldPerBlockForAll = () => {
    fetch(`${BASE_URL}/api/readtotalavgyieldperblock`, {
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); 
    })
    .then(data => {
      try {
        const jsonData = JSON.parse(data); 
        setAvgYieldPerBlock(jsonData[0]); // [0] needed bc its only returning one value but SQL always returns an array
      } catch(e) {
        console.error("The response is not JSON. Data:", data);
      }
    }) 
    .catch(error => {
      console.error('Error:', error);
    });
  } 
 
  const deleteFlushRecord = (id) => {
    setSelectedId(id);
    setOpenDialog(true); // open the dialog
  }

  const handleConfirmDelete = () => {
    // close the dialog first
    setOpenDialog(false);
  
    fetch(`${BASE_URL}/api/deleteflushrecord/${selectedId}`, {
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        toast.success('Record successfully deleted!', {
          autoClose: 1000, // Toast notification will close in 1 second
        });
        // Refresh the data
        return fetchFlushData(); 
      }
    })
    .catch(error => {
      console.error('Error:', error);
      toast.error('An error occurred while trying to delete the record');
    });
  }
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const editFlushRecord = (id) => {
    navigate(`/yieldedit/${id}`);
  };

  const fetchFarms = () => {
    fetch(`${BASE_URL}/api/readfarms`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      setFarms(data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  React.useEffect(() => {
    fetchFlushData();
    fetchAvgYieldPerBlockForAll();
    fetchFarms();
  }, [page, selectedFarm]);

  React.useEffect(() => {
    const sorted = sortData(flushData, sortField, sortDirection[sortField]);
    setSortedData(sorted);
  }, [flushData, sortField, sortDirection]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
  
    const dateParts = dateString.split('T')[0].split('-');
    // Create a new date object using the local time zone
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };
  
  const handleSort = (field) => {
    // Toggle the sort direction for the specific field
    const newDirection = sortDirection[field] === 'asc' ? 'desc' : 'asc';
  
    // Update the sort directions state
    setSortDirection({
      ...sortDirection,
      [field]: newDirection
    });
  
    // Set the sort field
    setSortField(field);
  };
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>

            <Box 
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, max-content) 1fr auto', // Three columns: button, title, empty space
                gap: 1, 
                alignItems: 'center',
                marginBottom: 2, 
              }}
            >

              {/* Title */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', // Centers the title
                  gridColumnStart: 3, // Title starts at the third column
                  gridColumnEnd: 3, // Title ends at the third column
                }}
              >
                <Typography variant="h6" component="h2" gutterBottom>
                  All yields
                </Typography>
              </Box>

              {/* Farm filter */}
              <FormControl fullWidth sx={{ gridColumnStart: 4, width: '145px' }}>
                <InputLabel id="farm-select-label">Filter farm</InputLabel>
                  <Select
                    labelId="farm-select-label"
                    id="farm-select"
                    value={selectedFarm}
                    label="Farm"
                    onChange={handleFarmChange}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {farms.map(farm => (
                      <MenuItem key={farm.farm_id} value={farm.farm_id}>
                        {farm.farm_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
              <Table aria-label="yields table" stickyHeader>

                <TableHead>
                  <TableRow>

                    {/* Who added */}
                    <TableCell 
                      align="left"
                      onClick={() => handleSort('strain_name')}
                    >
                      Who added {sortDirection['strain_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    {/* Number of blocks */}
                    {/* <TableCell 
                      align="right"
                      onClick={() => handleSort('num_of_blocks')}
                    >
                      Number of blocks {sortDirection['num_of_blocks'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell> */}

                    {/* Strain */}
                    <TableCell 
                      align="left"
                      onClick={() => handleSort('strain_name')}
                    >
                      Strain {sortDirection['strain_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>
                    
                    {/* Weight */}
                    <TableCell 
                      align="right"
                      onClick={() => handleSort('flush_weight')}
                    >
                      Weight {sortDirection['flush_weight']  === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    {/* Farm */}
                    <TableCell 
                      align="left"
                      onClick={() => handleSort('farm_name')}
                    >
                      Farm {sortDirection['farm_name']  === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    {/* Harvest date */}
                    <TableCell 
                      align="center"
                      onClick={() => handleSort('flush_date')}
                    >
                      Harvest date {sortDirection['flush_date']  === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    {/* Avg per block */}
                    {/* <TableCell 
                      align="center"
                      onClick={() => handleSort('flush_weight')}
                    >
                      Avg per block {sortDirection['flush_weight']  === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell> */}

                    <TableCell align="center">Edit</TableCell>
                    <TableCell align="center">Delete</TableCell>

                  </TableRow>
                </TableHead>

                <TableBody> 
                  {sortedData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="right">{row.first_name} {row.last_name}</TableCell>
                      {/* <TableCell align="right">{row.num_of_blocks}</TableCell> */}
                      <TableCell align="left">{row.strain_name}</TableCell>
                      <TableCell align="right">{row.flush_weight}</TableCell>
                      <TableCell align="left">{row.farm_name}</TableCell>
                      <TableCell>{formatDate(row.flush_date)}</TableCell>
                      {/* <TableCell align="right">{row.avg_yield_per_block.toFixed(2)}</TableCell> */}

                      {/* Edit Button */}
                      <TableCell>  
                        <IconButton aria-label="edit" onClick={() => editFlushRecord(row.flush_id)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>

                      {/* Delete Button */}
                      <TableCell>  
                        <IconButton aria-label="delete" onClick={() => deleteFlushRecord(row.flush_id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
              <Pagination 
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>

            <Typography align='right' sx={{ mt:2, mr:1 }}>
              {avgYieldPerBlock ? 
                `Average yield per block: ${avgYieldPerBlock.avg_yield_per_block.toFixed(2)}` : 
                'Loading average yield...'
              }
            </Typography>

          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{"Delete from yield table"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

export default YieldView;